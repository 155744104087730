html, body, #root, .App {
  height: 100%;
}

body {
  height: '100%';
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #141414 0% 0% no-repeat padding-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-main {
  top: 0px;
  left: 0px;
  height:100%;
  background: transparent url('assets/images/Component 6 – 1.png') 0% 0% no-repeat padding-box;
  background-position: center;
  background-size: cover;
  opacity: 1;
}

.App-main-box  {
  width: 50%;
  min-height: 30rem;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  /* border-radius: 15px; */
  transition: all .3s;
  position: relative;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%); 
  overflow: hidden;
}

.App-main-logo {
  background: transparent url('assets/images/nasa-Q1p7bh3SHj8-unsplash.png') 0% 0% no-repeat padding-box;
  background-position: center;
  background-size: cover;
  min-height: 30rem;
  opacity: 1;
}

.App-main-form {
  font: 'normal normal normal 18px/22px Roboto';
  min-height: 30rem;
  background: #141414 0% 0% no-repeat padding-box;
  opacity: 1;
}

.App-ecostream {
  /* background: transparent url('assets/images/ECHOSTREAM.svg') 0% 0% no-repeat padding-box;
  background-position: center;
  background-size: cover; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-80%, -40%);
  /* //opacity: 1; */
  height: 10rem;
  width: 50%;
  /* background-size: 300px 125px; */
}

.App-echo {
  font-family: 'Roboto';
  width: 20rem;
  height: 3.5rem;
  font-weight: bold;
  font-size: 3rem;
  color: white;
}

.App-stream {
  font-family: 'Roboto';
  width: 20rem;
  /* height: 10rem; */
  font-weight: bold;
  font-size: 3rem;
  color: #0EBCB7;
  margin: 0;
}

.buttonProgress {
  color: #F5F5F5;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -12;
}

#echo-pane-scroll {
  overflow: scroll;
  overflow-x: hidden;
  /* Firefox hide scrollbar */
  scrollbar-width: none;
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
}
/* Hide scrollbar for Chrome, Safari, and Opera */
#echo-pane-scroll::-webkit-scrollbar {
  display: none;
}

:root {
  --amplify-primary-color: #3f51b5;
  --amplify-primary-tint: #3f51b5;
  --amplify-primary-shade: #3f51b5;
  --amplify-background-color: #424242;
  --amplify-secondary-color: white;
  --amplify-secondary-contrast:#424242;
}